import React, { Component } from "react";
import { withVueRouter, applyVueInReact } from "vuereact-combined";
import axios from "@/axios";
import Payment from "../../components/payment/Payment";
import LoadingSpinner from "@/components/LoadingSpinner";
class IntenetPayment extends Component {
  LoadingSpinnerComp = applyVueInReact(LoadingSpinner);
  constructor() {
    super();
    this.state = {
      intentId: "",
      reports: "",
      message: "",
      loading: true,
    };
  }
  componentDidMount() {
    this.setState(
      {
        // intentId: this.props.$vueRoute.params.intentId,
        intentId: this.props.$vueRoute.query.intent,
      },
      () => {
        axios
          .get(`/api/v4/vendor/intent/verify?intent=${this.state.intentId}`)
          .then((response) => {
            this.setState({
              reports: response.data.data,
              loading: false,
            });
          });
      }
    );
  }
  render() {
    return (
      <div className="container">
        <div className="wallet-form-box card">
          <div className="card-body">
            <h5>Nimbuzz Pay</h5>
            <a href="/" className="btn btn-close">
              <i className="fa fa-times"></i>
            </a>
            {this.state.message && (
              <div className="form-group fade-animation">
                <div className="show-message">{this.state.message}</div>
              </div>
            )}
            <div className="row">
              <div className="col-md-7">
                {this.state.reports &&
                  (this.state.reports.isFulfilled ? (
                    <>Your payment has expired.</>
                  ) : (
                    <div className="fade-animation">
                      <div className="mt-4">
                        <div className="font-weight-bold">
                          YOU ARE PAYING AT
                        </div>
                        <div>{this.state.reports.vendor.name}</div>
                      </div>
                      <div className="mt-4">
                        <div className="font-weight-bold">
                          Total amount to pay
                        </div>
                        <div>Rs. {this.state.reports.amount}</div>
                      </div>
                      <div className="mt-4">
                        <div className="font-weight-bold">Purpose</div>
                        <div>{this.state.reports.purpose}</div>
                      </div>
                      <div className="mt-4">
                        <Payment
                          paymentUri="/api/v4/vendor/intent/payment"
                          serviceSlug="PaymentIntent"
                          paymentPayload={{
                            intent: this.state.intentId,
                          }}
                          setMessage={(message) => {
                            this.setState({ message });
                          }}
                          validationBeforePayment={this.validationBeforePayment}
                          paymentPreviewDetail={{
                            "Vendor Name": this.state.reports.vendor.name,
                            "Total Amount to Pay":
                              "Rs. " + this.state.reports.amount,
                          }}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <this.LoadingSpinnerComp loading={this.state.loading} />
          </div>
        </div>
      </div>
    );
  }
}
export default withVueRouter(IntenetPayment);
